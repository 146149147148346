<template>
  <v-container fluid>
    <v-row v-if="!anyCurrentLoan">
      <v-btn class="primary" block to="/member/loan-application">Apply for new Loan</v-btn>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title>
            <div>
              <v-btn text :loading="loading" color="primary">
                {{ mostRecentLoan.principal | toCurrency }}
              </v-btn>
            </div>
          </v-card-title>
          <v-card-subtitle>
            <div>Amount borrowed</div>
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title>
            <div>
              <v-btn text :loading="loading" color="primary">
                {{ mostRecentLoan.dateAppliedStamp | filterDate }}
              </v-btn>
            </div>
          </v-card-title>
          <v-card-subtitle>
            <div>Date Applied</div>
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title>
            <div>
              <v-btn text :loading="loading" color="primary">
                {{ mostRecentLoan.status }}
              </v-btn>
            </div>
          </v-card-title>
          <v-card-subtitle>
            <div>Status</div>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title>
            <div>
              <v-btn text :loading="loading" color="primary">{{ mostRecentLoan.nextDueTimestamp | filterDate }}
              </v-btn>
            </div>
          </v-card-title>
          <v-card-subtitle>
            <div>Next Payment Date</div>
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title>
            <div>
              <div>
                <v-btn text :loading="loading" color="primary">
                  {{ mostRecentLoan.installment | toCurrency }}
                </v-btn>
              </div>
            </div>
          </v-card-title>
          <v-card-subtitle>
            <div>Next Payment Amount</div>
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title>
            <div>
              <v-btn text :loading="loading" color="primary">
                {{ (0 - mostRecentLoan.balance) | toCurrency }}
              </v-btn>
            </div>
          </v-card-title>
          <v-card-subtitle>
            <div>Total Amount Owed</div>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title>
            <div>
              <v-btn text :loading="loading" color="primary">{{ mostRecentLoan.paymentsLeft }}
              </v-btn>
            </div>
          </v-card-title>
          <v-card-subtitle>
            <div>Number of payments left</div>
          </v-card-subtitle>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <v-card-title>
            <div>
              <v-btn text :loading="loading" color="primary">
                {{ mostRecentLoan.maturityDateStamp | filterDate }}
              </v-btn>
            </div>
          </v-card-title>
          <v-card-subtitle>
            <div>Maturity Date</div>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data() {
    return {
      mostRecentLoan: {},
    };
  },
  filters: {
    filterDate(value) {
      if (!value) return null;
      return new Date(value || 0).toISOString().split("T")[0];
    },

    /* filterDate(value) {
      if (!value) return "";
      if (value != 0 && !value) return "";
      const parts = value.split("T");
      //if (parts.length === 1) return value;
      return parts[0];
    }, */
    toCurrency(value) {
      if (typeof value !== "number" || !value) {
        return value || "--";
      }

      const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      });
      return formatter.format(value);
    },
  },
  computed: {
    anyCurrentLoan() {
      return this.$store.getters["loan/anyCurrentLoan"];
    },
    loading() {
      return !this.$store.state.profile.loaded;
    },
    selectedLoan() {
      return this.$store.state.loan.selectedLoan;
    },

    mostRecentLoanId() {
      return this.$store.getters["loan/mostRecentLoanId"];
    },
    lastTransaction() {
      return this.$store.getters["loan/lastTransaction"];
    },
    transactions() {
      return this.$store.getters["loan/selectedLoanTransactions"];
    },
    balance() {
      return this.$store.getters["loan/outstandingBalance"];
    },
  },
  watch: {
    actualTermsPassed: function (val) {
      console.log("actualTermsPassed", val);
    },
    mostRecentLoanId: function (val) {
      console.log("mostRecentLoan", val);
    },
    selectedLoan: {
      handler: function () {
        this.processLoan();
      },
      deep: true,
      immediate: true
    },
  },
  mounted() {
    console.log("is production", this.$orendaIsProduction);
    this.$store.dispatch("loan/loadLoans").then(() => {
      if (this.mostRecentLoanId)
        this.$store.dispatch("loan/getLoan", this.mostRecentLoanId);
    })
  },
  methods: {
    processLoan() {
      let {
        // id,
        //  currency,
        status,
        days,
        term,
        //periodType,
        date_applied,
        date_approved,
        installment,
        date_first_payment_due,
        amount_requested,
        principal,
        fees,
        interests,
      } = this.selectedLoan ?? {};

      console.log("this.selectedLoan", this.selectedLoan);

      date_approved = date_approved || date_applied;

      const dayMillises = 1000 * 24 * 60 * 60;

      principal = principal || amount_requested;
      days = days || 28;
      term = term || 1;

      const amount_owe = principal + (fees || 0) + (interests || 0);
      //installment = installment || amount_owe;

      const { balance } = this;

      console.log("dashboard===balance", balance);

      const paymentsLeft =
        Math.ceil(-balance / (installment || -balance), 10) || 0;
      const dateAppliedStamp = new Date(date_applied).getTime();
      const dateApprovedStamp = new Date(date_approved).getTime();

      let maturityDateStamp;
      let firstDueTimestamp;
      let periodInMillis;

      if (term === 1) {
        periodInMillis = days * dayMillises;
        firstDueTimestamp = date_first_payment_due
          ? new Date(date_first_payment_due).getTime()
          : dateApprovedStamp + periodInMillis;
        maturityDateStamp = firstDueTimestamp;
      } else {
        periodInMillis = (days / (term + 1)) * dayMillises;
        firstDueTimestamp = date_first_payment_due
          ? new Date(date_first_payment_due).getTime()
          : dateApprovedStamp + periodInMillis;
        maturityDateStamp = dateApprovedStamp + days * dayMillises;
      }

      /*  let lastPaymentTimestamp = (() => {
        const descTrans = this.transactions
          .filter((x) => x.type === "Payment" && !x.cancelled)
          .map((trx) => {
            trx.timestamp = new Date(trx.created).getTime();
            return trx;
          })
          .sort((a, b) => b.timestamp - a.timestamp);
        return descTrans.length > 0 ? descTrans[0].timestamp : dateAppliedStamp;
      })(); */

      const totalPaymentMade = this.transactions.reduce((total, x) => {
        if (x.type == "Payment") total += x.amount;
        return total;
      }, 0);

      let currentPeriodNo =
        Math.ceil((Date.now() - firstDueTimestamp) / periodInMillis) - 1;

      if (currentPeriodNo > term - 1) currentPeriodNo = term - 1;
      if (currentPeriodNo < 0) currentPeriodNo = 0;

      let nextDueTimestamp =
        firstDueTimestamp + currentPeriodNo * periodInMillis;

      const expectedPayments = currentPeriodNo * installment;
      const expectedBalance = amount_owe - expectedPayments;
      console.log("before testing paramters", {
        maturityDateStamp,
        firstDueTimestamp,
        periodInMillis,
        term,
        date_first_payment_due,
        balance,
        installment,
        currentPeriodNo,
        nextDueTimestamp,
        expectedBalance,
        expectedPayments,
      });

      //lastPaymentTimestamp > nextDueTimestamp
      if (balance >= 0) {
        installment = 0;
      } else if (totalPaymentMade >= expectedPayments) {
        ///nextDueTimestamp += periodInMillis;
        installment = expectedPayments + installment - totalPaymentMade;
      } else {
        installment = expectedPayments - totalPaymentMade;
      }

      this.mostRecentLoan = {
        status,
        nextDueTimestamp,
        firstDueTimestamp,
        dateAppliedStamp,
        installment,
        maturityDateStamp,
        principal,
        balance,
        paymentsLeft,
      };
    },
  },
};
</script>
